import classNames from 'classnames'
import React, { FC } from 'react'

import { NetworkConfigV3 } from '../../model'

const IconAmm: FC<{
  logoURI: string
  displayName: string
  className?: string
  'data-tip'?: string
  network?: NetworkConfigV3
  size?: 'lg' | 'md' | 'sm' | 'xs'
}> = (props) => {
  const networkStyle =
    props.network && props.network?.name !== 'eth'
      ? ({
          '--dexguru-network-content': '""',
          '--dexguru-network-color': props.network?.color,
        } as React.CSSProperties)
      : undefined

  return (
    <div
      className={classNames(
        'amm-icon',
        { [`amm-icon--${props.size}`]: !!props.size },
        props.className
      )}
      style={networkStyle}>
      <img
        className={classNames('amm-icon__image')}
        src={props.logoURI}
        alt={props.displayName}
        data-for="app-tooltip"
        data-tip={props['data-tip']}
      />
    </div>
  )
}

export default IconAmm
